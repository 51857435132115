import { IntlShape } from 'react-intl';
import store from 'store';
import { DATATABLE_TYPE, FILTER_TYPES, SPECIAL_TABLE_TYPES } from 'state-domains/constants';
import {
    CATEGORY_TYPES,
    DrillHoleReferencesIntervalType,
    ExportDestination,
    ExportEncoding,
    FilterObject,
    FilterOperator,
    QuotesMode,
    SystemFilter,
    UserWithType,
} from 'state-domains/domain';
import { CheckboxSelectMenuOption } from 'src/components/CheckboxSelectMenu/CheckboxSelectMenu.types';
import { CACHED_FILTERS_STORE } from 'src/components/FilterButton/Filter.utils';
import { FilterButtonProps } from 'src/components/FilterButton/FilterButton.types';
import { HeaderFieldInfoValues } from 'src/utilities/types';
import { GenericObject } from 'src/utilities';

import { EVENT_TYPES } from '../Events/Events/Events.utils';

import { i18n } from './Filter.i18n';
import { FilterDataCollection, FilterPathTypes } from './Filter.types';

export const CONFIGURATION = 'configuration';

const metaDataSystemFilters = [
    '5acd1e9d83908ae842bfe203',
    '5acd1e9d83908ae842bfe204',
    '5acd1e9d83908ae842bfe205',
    '5acd1e9d83908ae842bfe206',
];

const getLocation = (
    path: string[],
    item: GenericObject,
    accumValue: any,
    type: FilterPathTypes,
) => {
    const subPath = path.shift();

    // When traversing item path, these two identifiers handle
    // traversing dictionaries and arrays
    if (['[id]', '[itr]'].includes(subPath ?? '')) {
        (subPath === '[id]' ? Object.values(item ?? {}) : item).forEach((x: any) => {
            getLocation([...path], x, accumValue, type);
        });
    } else {
        const newItem = item[subPath ?? ''];
        if (newItem) {
            if (path.length) {
                getLocation(path, newItem, accumValue, type);
            } else {
                const collection = [];

                switch (type) {
                    case FilterPathTypes.DICT:
                        collection.push(...Object.keys(newItem ?? {}));
                        break;
                    case FilterPathTypes.STR:
                    default:
                        collection.push(newItem);
                        break;
                }

                collection.forEach((y) => (accumValue[y] = true));
            }
        }
    }
};

const getFilterCollectionFromParentCollection = (
    locationParams: { path: string; type: FilterPathTypes }[],
    parentCollection: any[],
    filterCollection: any[],
    isUser = false,
): any[] => {
    const filterInfo = {};

    parentCollection.forEach((value: any) =>
        locationParams.forEach((x) => getLocation(x.path.split('.'), value, filterInfo, x.type)),
    );
    const ids = Object.keys(filterInfo ?? {});

    return filterCollection
        .filter((x: any) => ids.includes(x.id))
        .filter((x: any) => (isUser ? x?.profile?.name : x?.name))
        .sort((a: any, b: any) =>
            isUser
                ? (a.profile.name ?? '').localeCompare(b.profile.name ?? '')
                : a.name.localeCompare(b.name),
        )
        .map((y, idx) => ({
            id: y.id,
            index: idx,
            ...(isUser ? { value: y.profile.name ?? '', email: y.email } : { value: y.name }),
        }));
};

export const getFilters = (type: DATATABLE_TYPE, userId: string, subscriptionId: string): any[] => {
    const allFilters = store.get(`${CACHED_FILTERS_STORE}_${userId}_${subscriptionId}`, {});
    if (allFilters?.[CONFIGURATION]) {
        return allFilters[CONFIGURATION][type.toString()] ?? [];
    }
    return [];
};

export const findUsersInCollection = (
    users: Record<string, UserWithType>,
    collection: any[],
    fields: string[],
): CheckboxSelectMenuOption[] => {
    const userCollection: CheckboxSelectMenuOption[] = Object.values(
        collection.reduce((accum: Record<string, CheckboxSelectMenuOption>, value) => {
            for (const field of fields) {
                if (!value[field] || value[field] in accum || !users[value[field]]) continue;

                const user = users[value[field]];

                accum[value[field]] = {
                    id: user.id,
                    value: user.profile.name,
                    email: user.email,
                };
            }

            return accum;
        }, {}),
    );

    return userCollection.sort((a, b) => (a.value > b.value ? 1 : -1));
};

export const findUsedInCollection = (collection: any[], searchCollection: Record<string, any>) => {
    const usedInCollection: HeaderFieldInfoValues[] = Object.values(
        collection.reduce((accum: Record<string, HeaderFieldInfoValues>, value) => {
            const tablesAndHeaders: string[] = [];

            Object.keys(value.usedIn ?? {}).forEach((x) =>
                tablesAndHeaders.push(...Object.keys(value.usedIn[x])),
            );
            tablesAndHeaders.forEach((x: any, idx: number) => {
                if (!(x in accum) && searchCollection[x]) {
                    accum[x] = {
                        id: x,
                        value: searchCollection[x].name,
                        index: idx,
                    };
                }
            });
            return accum;
        }, {}),
    );

    return usedInCollection
        .map((x, idx) => ({ ...x, index: idx }))
        .sort((a, b) => (a.value > b.value ? 1 : -1));
};

export const convertUserFilterInfo = (
    type: DATATABLE_TYPE,
    userId: string,
    subscriptionId: string,
    collection: any[],
    data: {
        systemFilters: Record<string, SystemFilter>;
        filterOperators: Record<string, FilterOperator>;
    },
): FilterObject[] =>
    getFilters(type, userId, subscriptionId).map((filter: any) => {
        let userFilter: any = {
            type: filter.type,
            selectedValues: filter.values,
        };

        const filterOperator = data.filterOperators[filter.type];

        if (filterOperator?.hasOperator) {
            userFilter = {
                ...userFilter,
                operator: filterOperator.operators.find(
                    (operator) => operator.name === filter.operator,
                ),
            };
        }

        if (filter.system_filter) {
            const systemFilter = Object.values(data.systemFilters).find(
                (x) => x.name === filter.system_filter,
            );

            userFilter = {
                ...userFilter,
                name: filter.system_filter,
                label: systemFilter?.label,
                id: systemFilter?.id,
            };
        } else {
            userFilter = {
                ...collection.find((value) => value.id === filter.field),
                ...userFilter,
            };
        }

        if ('values' in userFilter) delete userFilter.values;

        return userFilter;
    });

export const constructFilterParams = (
    intl: IntlShape,
    type: DATATABLE_TYPE,
    subscriptionId: string,
    data: FilterDataCollection,
) => {
    const filterParams: FilterButtonProps = {
        currentUserOptions: findUsersInCollection(data.users, Object.values(data.mainCollection), [
            'createdBy',
            'updatedBy',
        ]),
    } as FilterButtonProps;
    const usedInCollection = findUsedInCollection(
        Object.values(data.mainCollection),
        data.usedInSearchCollection,
    );
    let defaultFilterOptions: any[] = [];

    const systemFilters = Object.values(data.systemFilters)
        .filter((value) => metaDataSystemFilters.includes(value.id))
        .sort((a, b) => a.order - b.order);

    const commonFilterDictionary = {
        name: {
            dynamicFilter: true,
            id: 'name',
            label: intl.formatMessage(i18n.name),
            name: intl.formatMessage(i18n.name),
            type: FILTER_TYPES.TEXT,
        },
        description: {
            dynamicFilter: true,
            id: 'description',
            label: intl.formatMessage(i18n.description),
            name: intl.formatMessage(i18n.description),
            type: FILTER_TYPES.TEXT,
        },
        category: {
            dynamicFilter: true,
            id: 'category',
            label: intl.formatMessage(i18n.category),
            name: intl.formatMessage(i18n.category),
            type: FILTER_TYPES.LIST,
            values: Object.values(data?.categories ?? [])
                .map((x, idx) => ({ id: x.id, value: x.name, index: idx }))
                .sort((a, b) => (a.value > b.value ? 1 : -1)),
        },
        ismx: {
            dynamicFilter: true,
            id: 'ismx',
            label: intl.formatMessage(i18n.source),
            name: intl.formatMessage(i18n.source),
            type: FILTER_TYPES.LIST,
            values: [
                {
                    id: true,
                    value: intl.formatMessage(i18n.sourceMx),
                    index: 0,
                },
                {
                    id: false,
                    value: intl.formatMessage(i18n.sourceSite),
                    index: 1,
                },
            ],
        },
        usedIn: {
            dynamicFilter: true,
            id: 'used_in',
            label: intl.formatMessage(i18n.usedIn),
            name: intl.formatMessage(i18n.usedIn),
            type: FILTER_TYPES.LIST_OBJECT,
            values: usedInCollection,
        },
    };

    const { name, description, category, usedIn, ...rest } = commonFilterDictionary;

    const systemFiltersWithUsers = [
        {
            dynamicFilter: true,
            id: '_created_at',
            label: 'Created On',
            name: 'Created On',
            type: FILTER_TYPES.DATE,
        },
        {
            dynamicFilter: true,
            id: '_created_by',
            label: 'Created By',
            name: 'Created By',
            type: FILTER_TYPES.LIST,
            values: data.additionalCollections?.users ?? [],
        },
        {
            dynamicFilter: true,
            id: '_updated_at',
            label: 'Updated On',
            name: 'Updated On',
            type: FILTER_TYPES.DATE,
        },
        {
            dynamicFilter: true,
            id: '_updated_by',
            label: 'Updated By',
            name: 'Updated By',
            type: FILTER_TYPES.LIST,
            values: data.additionalCollections?.users ?? [],
        },
    ];

    let filtersToRemoveFromDefaultFilters: string[] = [];
    switch (type) {
        case DATATABLE_TYPE.LISTS:
        case DATATABLE_TYPE.SAMPLE_LISTS:
            if (type === DATATABLE_TYPE.SAMPLE_LISTS) {
                filtersToRemoveFromDefaultFilters = [
                    'Category',
                    'created_by',
                    'created_date',
                    'Source',
                    'Used in',
                ];
            }
            defaultFilterOptions = [...Object.values(commonFilterDictionary), ...systemFilters];
            break;
        case DATATABLE_TYPE.TABLES:
            defaultFilterOptions = [
                name,
                {
                    id: 'label',
                    dynamicFilter: true,
                    label: intl.formatMessage(i18n.label),
                    name: intl.formatMessage(i18n.label),
                    type: FILTER_TYPES.TEXT,
                },
                description,
                category,
                {
                    dynamicFilter: true,
                    id: 'interval_type',
                    label: intl.formatMessage(i18n.tableType),
                    name: intl.formatMessage(i18n.tableType),
                    type: FILTER_TYPES.LIST,
                    values: [
                        {
                            id: DrillHoleReferencesIntervalType.INTERVAL,
                            value: intl.formatMessage(i18n.tableTypeInterval),
                            index: 0,
                        },
                        {
                            id: DrillHoleReferencesIntervalType.DEPTH,
                            value: intl.formatMessage(i18n.tableTypeDepth),
                            index: 1,
                        },
                        {
                            id: DrillHoleReferencesIntervalType.DATA,
                            value: intl.formatMessage(i18n.tableTypeData),
                            index: 2,
                        },
                    ],
                },
                {
                    dynamicFilter: true,
                    id: 'special_table',
                    label: intl.formatMessage(i18n.specialTable),
                    name: intl.formatMessage(i18n.specialTable),
                    type: FILTER_TYPES.LIST,
                    values: [
                        ...Object.values(SPECIAL_TABLE_TYPES).map((x, idx) => ({
                            id: x,
                            value: intl.formatMessage(i18n[x]),
                            index: idx,
                        })),
                    ],
                },
                {
                    dynamicFilter: true,
                    id: 'linked_to_lithology',
                    label: intl.formatMessage(i18n.linkedToLithology),
                    name: intl.formatMessage(i18n.linkedToLithology),
                    type: FILTER_TYPES.CHECKBOX,
                    operatorLabelOverrides: {
                        checked: intl.formatMessage(i18n.isLinkedToLithology),
                        not_checked: intl.formatMessage(i18n.isNotLinkedToLithology),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'allow_gaps',
                    label: intl.formatMessage(i18n.gaps),
                    name: intl.formatMessage(i18n.gaps),
                    type: FILTER_TYPES.CHECKBOX,
                    operatorLabelOverrides: {
                        checked: intl.formatMessage(i18n.allowGaps),
                        not_checked: intl.formatMessage(i18n.notAllowGaps),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'allow_overlaps',
                    label: intl.formatMessage(i18n.overlaps),
                    name: intl.formatMessage(i18n.overlaps),
                    type: FILTER_TYPES.CHECKBOX,
                    operatorLabelOverrides: {
                        checked: intl.formatMessage(i18n.allowOverlaps),
                        not_checked: intl.formatMessage(i18n.notAllowOverlaps),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'allow_dupes',
                    label: intl.formatMessage(i18n.duplicates),
                    name: intl.formatMessage(i18n.duplicates),
                    type: FILTER_TYPES.CHECKBOX,
                    operatorLabelOverrides: {
                        checked: intl.formatMessage(i18n.allowDuplicates),
                        not_checked: intl.formatMessage(i18n.notAllowDuplicates),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'single_table',
                    label: intl.formatMessage(i18n.tableOrTableView),
                    name: intl.formatMessage(i18n.tableOrTableView),
                    type: FILTER_TYPES.EXISTS,
                    operatorLabelOverrides: {
                        has_values: intl.formatMessage(i18n.table),
                        empty: intl.formatMessage(i18n.tableView),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'parent',
                    label: intl.formatMessage(i18n.childTable),
                    name: intl.formatMessage(i18n.childTable),
                    type: FILTER_TYPES.EXISTS,
                    operatorLabelOverrides: {
                        has_values: intl.formatMessage(i18n.isChildTable),
                        empty: intl.formatMessage(i18n.isNotChildTable),
                    },
                },
                ...Object.values(rest),
                usedIn,
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.SAMPLE_HEADERS:
            defaultFilterOptions = [
                name,
                description,
                category,
                ...Object.values(rest),
                usedIn,
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.HEADERS:
            defaultFilterOptions = [
                name,
                description,
                category,
                {
                    dynamicFilter: true,
                    id: 'type',
                    label: intl.formatMessage(i18n.headerType),
                    name: intl.formatMessage(i18n.headerType),
                    type: FILTER_TYPES.LIST,
                    values: [
                        {
                            id: 'drilling',
                            value: intl.formatMessage(i18n.drilling),
                            index: 0,
                        },
                        {
                            id: 'point',
                            value: intl.formatMessage(i18n.point),
                            index: 1,
                        },
                    ],
                },
                ...Object.values(rest),
                usedIn,
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.FIELDS:
        case DATATABLE_TYPE.SAMPLE_FIELDS:
            defaultFilterOptions = [
                name,
                // description,
                // category,
                {
                    dynamicFilter: true,
                    id: 'type',
                    label: intl.formatMessage(i18n.dataType),
                    name: intl.formatMessage(i18n.dataType),
                    type: FILTER_TYPES.LIST,
                    values: [
                        {
                            id: 'text',
                            value: intl.formatMessage(i18n.text),
                            index: 0,
                        },
                        {
                            id: 'numeric',
                            value: intl.formatMessage(i18n.numeric),
                            index: 1,
                        },
                        {
                            id: 'checkbox',
                            value: intl.formatMessage(i18n.checkbox),
                            index: 0,
                        },
                        {
                            id: 'file',
                            value: intl.formatMessage(i18n.file),
                            index: 0,
                        },
                        {
                            id: 'list',
                            value: intl.formatMessage(i18n.list),
                            index: 0,
                        },
                        {
                            id: 'date',
                            value: intl.formatMessage(i18n.date),
                            index: 0,
                        },
                    ],
                },
                commonFilterDictionary.ismx,
                // ...Object.values(rest),
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.ACTIVITIES:
            defaultFilterOptions = [
                name,
                description,
                category,
                {
                    dynamicFilter: true,
                    id: 'type',
                    label: intl.formatMessage(i18n.type),
                    name: intl.formatMessage(i18n.type),
                    type: FILTER_TYPES.LIST,
                    values: [
                        {
                            id: 'drilling',
                            value: intl.formatMessage(i18n.drilling),
                            index: 0,
                        },
                        {
                            id: 'point',
                            value: intl.formatMessage(i18n.point),
                            index: 1,
                        },
                    ],
                },
                {
                    dynamicFilter: true,
                    id: 'tables',
                    label: intl.formatMessage(i18n.assignedTables),
                    name: intl.formatMessage(i18n.assignedTables),
                    type: FILTER_TYPES.LIST_OBJECT,
                    values: getFilterCollectionFromParentCollection(
                        data.relatedCollectionsFilters.availableTables.locationParams,
                        Object.values(data.mainCollection),
                        data.relatedCollectionsFilters.availableTables.filterCollection,
                    ),
                },
                {
                    dynamicFilter: true,
                    id: 'header',
                    label: intl.formatMessage(i18n.assignedHeaders),
                    name: intl.formatMessage(i18n.assignedHeaders),
                    type: FILTER_TYPES.LIST,
                    values: getFilterCollectionFromParentCollection(
                        data.relatedCollectionsFilters.availableHeaders.locationParams,
                        Object.values(data.mainCollection),
                        data.relatedCollectionsFilters.availableHeaders.filterCollection,
                    ),
                },
                usedIn,
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.PROJECTS:
            defaultFilterOptions = [
                name,
                description,
                {
                    dynamicFilter: true,
                    id: 'activities',
                    label: intl.formatMessage(i18n.assignedActivities),
                    name: intl.formatMessage(i18n.assignedActivities),
                    type: FILTER_TYPES.LIST_OBJECT,
                    values: getFilterCollectionFromParentCollection(
                        data.relatedCollectionsFilters.availableActivities.locationParams,
                        Object.values(data.mainCollection),
                        data.relatedCollectionsFilters.availableActivities.filterCollection,
                    ),
                },
                {
                    dynamicFilter: true,
                    id: 'users',
                    label: intl.formatMessage(i18n.assignedUsers),
                    name: intl.formatMessage(i18n.assignedUsers),
                    type: FILTER_TYPES.LIST_OBJECT,
                    values: getFilterCollectionFromParentCollection(
                        data.relatedCollectionsFilters.availableUsers.locationParams,
                        Object.values(data.mainCollection),
                        data.relatedCollectionsFilters.availableUsers.filterCollection,
                        true,
                    ),
                },
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.SAMPLE_ANALYSIS_WORKFLOWS:
            defaultFilterOptions = [name, description, usedIn, ...systemFilters];
            break;
        case DATATABLE_TYPE.ACTIVITY_GROUPS:
            defaultFilterOptions = [name, ...systemFilters];
            break;
        case DATATABLE_TYPE.GRIDS:
            defaultFilterOptions = [
                name,
                description,
                {
                    ...commonFilterDictionary.ismx,
                    label: intl.formatMessage(i18n.type),
                    name: intl.formatMessage(i18n.type),
                    values: [
                        {
                            id: true,
                            value: intl.formatMessage(i18n.world),
                            index: 0,
                        },
                        {
                            id: false,
                            value: intl.formatMessage(i18n.custom),
                            index: 1,
                        },
                    ],
                },
                {
                    dynamicFilter: true,
                    id: 'isGeo',
                    label: intl.formatMessage(i18n.isGeographic),
                    name: intl.formatMessage(i18n.isGeographic),
                    type: FILTER_TYPES.EXISTS,
                    operatorLabelOverrides: {
                        has_values: intl.formatMessage(i18n.geographic),
                        empty: intl.formatMessage(i18n.projection),
                    },
                },
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.CATEGORIES:
            defaultFilterOptions = [
                name,
                {
                    dynamicFilter: true,
                    id: 'module',
                    label: intl.formatMessage(i18n.module),
                    name: intl.formatMessage(i18n.module),
                    type: FILTER_TYPES.LIST,
                    values: Object.values(CATEGORY_TYPES).map((x, idx) => ({
                        id: x,
                        value: x,
                        index: idx,
                    })),
                },
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.EVENTS:
        case DATATABLE_TYPE.TEMP_EVENTS:
            defaultFilterOptions = [
                {
                    dynamicFilter: true,
                    id: 'event_type',
                    label: 'Event',
                    name: 'Event',
                    type: FILTER_TYPES.LIST,
                    values: Object.values(EVENT_TYPES)
                        .filter((x) => x in i18n)
                        .map((x, idx) => ({
                            id: x,
                            index: idx,
                            value: intl.formatMessage(i18n[x]),
                        })),
                },
                {
                    dynamicFilter: true,
                    id: 'project',
                    label: 'Project',
                    name: 'Project',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.projects ?? [],
                },
                {
                    dynamicFilter: true,
                    id: 'activity',
                    label: 'Activity',
                    name: 'Activity',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.activities ?? [],
                },
                {
                    dynamicFilter: true,
                    id: 'reference_type',
                    label: 'Linked Object Type',
                    name: 'Linked Object Type',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.linkedObjectTypes ?? [],
                },
                {
                    dynamicFilter: true,
                    id: 'reference_name',
                    label: 'Linked Object',
                    name: 'Linked Object',
                    type: FILTER_TYPES.TEXT,
                },
                {
                    dynamicFilter: true,
                    id: 'reference_id',
                    label: 'Linked Object Id',
                    name: 'Linked Object Id',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.objectIds ?? [],
                    isSingleValueDisabled: true,
                },
                ...systemFiltersWithUsers,
            ];
            break;
        case DATATABLE_TYPE.POSTS:
        case DATATABLE_TYPE.TEMP_POSTS:
            defaultFilterOptions = [
                {
                    dynamicFilter: true,
                    id: 'project',
                    label: 'Project',
                    name: 'Project',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.projects ?? [],
                },
                {
                    dynamicFilter: true,
                    id: 'activity',
                    label: 'Activity',
                    name: 'Activity',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.activities ?? [],
                },
                {
                    dynamicFilter: true,
                    id: 'content',
                    label: 'Comment',
                    name: 'Comment',
                    type: FILTER_TYPES.TEXT,
                },
                {
                    dynamicFilter: true,
                    id: 'linked_object_type',
                    label: 'Linked Object Type',
                    name: 'Linked Object Type',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.linkedObjectTypes ?? [],
                },
                {
                    dynamicFilter: true,
                    id: 'linked_object_name',
                    label: 'Linked Object',
                    name: 'Linked Object',
                    type: FILTER_TYPES.TEXT,
                },
                {
                    dynamicFilter: true,
                    id: 'tagged_users',
                    label: 'Tagged Users',
                    name: 'Tagged Users',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.users ?? [],
                },
                {
                    dynamicFilter: true,
                    id: 'attachments',
                    label: 'Attachments',
                    name: 'Attachments',
                    type: FILTER_TYPES.EXISTS,
                    operatorLabelOverrides: {
                        has_values: intl.formatMessage(i18n.hasFiles),
                        empty: intl.formatMessage(i18n.doesntHaveFiles),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'linked_object_id',
                    name: 'Linked Object Id',
                    label: 'Linked Object Id',
                    type: FILTER_TYPES.LIST,
                    values: data.additionalCollections?.objectIds ?? [],
                    isSingleValueDisabled: true,
                },
                ...systemFiltersWithUsers,
            ];
            break;
        case DATATABLE_TYPE.EXPORT_TEMPLATES:
            defaultFilterOptions = [
                name,
                {
                    dynamicFilter: true,
                    id: 'code',
                    label: intl.formatMessage(i18n.code),
                    name: intl.formatMessage(i18n.code),
                    type: FILTER_TYPES.TEXT,
                },
                description,
                {
                    dynamicFilter: true,
                    id: 'destination',
                    label: intl.formatMessage(i18n.destination),
                    name: intl.formatMessage(i18n.destination),
                    type: FILTER_TYPES.LIST_OBJECT,
                    values: [
                        {
                            id: ExportDestination.CSV,
                            index: 0,
                            value: intl.formatMessage(i18n[ExportDestination.CSV]),
                        },
                        {
                            id: ExportDestination.EVO_CSV,
                            index: 1,
                            value: intl.formatMessage(i18n[ExportDestination.EVO_CSV]),
                        },
                        {
                            id: ExportDestination.XLSX,
                            index: 2,
                            value: intl.formatMessage(i18n[ExportDestination.XLSX]),
                        },
                        {
                            id: ExportDestination.EVO,
                            index: 3,
                            value: intl.formatMessage(i18n[ExportDestination.EVO]),
                        },
                    ],
                },
                {
                    dynamicFilter: true,
                    id: 'is_valid',
                    label: intl.formatMessage(i18n.validity),
                    name: intl.formatMessage(i18n.validity),
                    type: FILTER_TYPES.EXISTS,
                    operatorLabelOverrides: {
                        has_values: intl.formatMessage(i18n.isValid),
                        empty: intl.formatMessage(i18n.isNotValid),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'project',
                    name: intl.formatMessage(i18n.project),
                    label: intl.formatMessage(i18n.project),
                    type: FILTER_TYPES.LIST,
                    values: getFilterCollectionFromParentCollection(
                        data.relatedCollectionsFilters.availableProjects.locationParams,
                        Object.values(data.mainCollection),
                        data.relatedCollectionsFilters.availableProjects.filterCollection,
                    ),
                },
                {
                    dynamicFilter: true,
                    id: 'type',
                    name: intl.formatMessage(i18n.type),
                    label: intl.formatMessage(i18n.type),
                    type: FILTER_TYPES.LIST,
                    values: [
                        {
                            id: 'drilling',
                            value: intl.formatMessage(i18n.drilling),
                            index: 0,
                        },
                        {
                            id: 'point',
                            value: intl.formatMessage(i18n.point),
                            index: 1,
                        },
                    ],
                },
                {
                    dynamicFilter: true,
                    id: 'is_shared_template',
                    label: intl.formatMessage(i18n.shared),
                    name: intl.formatMessage(i18n.shared),
                    type: FILTER_TYPES.EXISTS,
                    operatorLabelOverrides: {
                        has_values: intl.formatMessage(i18n.isShared),
                        empty: intl.formatMessage(i18n.isNotShared),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'encoding',
                    name: intl.formatMessage(i18n.encoding),
                    label: intl.formatMessage(i18n.encoding),
                    type: FILTER_TYPES.LIST,
                    values: Object.values(ExportEncoding)
                        .sort((a, b) => a.localeCompare(b))
                        .map((x, idx) => ({
                            id: x,
                            value: intl.formatMessage(i18n[x]),
                            index: idx,
                        })),
                },
                {
                    dynamicFilter: true,
                    id: 'quotes_mode',
                    name: intl.formatMessage(i18n.quoting),
                    label: intl.formatMessage(i18n.quoting),
                    type: FILTER_TYPES.LIST,
                    values: Object.values(QuotesMode)
                        .sort((a, b) => a.localeCompare(b))
                        .map((x, idx) => ({
                            id: x,
                            value: intl.formatMessage(i18n[x]),
                            index: idx,
                        })),
                },
                {
                    dynamicFilter: true,
                    id: 'grid',
                    name: intl.formatMessage(i18n.grid),
                    label: intl.formatMessage(i18n.grid),
                    type: FILTER_TYPES.LIST,
                    values: getFilterCollectionFromParentCollection(
                        data.relatedCollectionsFilters.availableGrids.locationParams,
                        Object.values(data.mainCollection),
                        data.relatedCollectionsFilters.availableGrids.filterCollection,
                    ),
                },
                {
                    dynamicFilter: true,
                    id: 'date_format',
                    label: intl.formatMessage(i18n.format),
                    name: intl.formatMessage(i18n.format),
                    type: FILTER_TYPES.TEXT,
                },
                {
                    dynamicFilter: true,
                    id: 'include_files',
                    label: intl.formatMessage(i18n.includeFiles),
                    name: intl.formatMessage(i18n.includeFiles),
                    type: FILTER_TYPES.EXISTS,
                    operatorLabelOverrides: {
                        has_values: intl.formatMessage(i18n.doesIncludeFiles),
                        empty: intl.formatMessage(i18n.doesntIncludeFiles),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'include_metadata',
                    label: intl.formatMessage(i18n.includeMetaData),
                    name: intl.formatMessage(i18n.includeMetaData),
                    type: FILTER_TYPES.EXISTS,
                    operatorLabelOverrides: {
                        has_values: intl.formatMessage(i18n.doesIncludeMetadata),
                        empty: intl.formatMessage(i18n.doesntIncludeMetadata),
                    },
                },
                {
                    dynamicFilter: true,
                    id: 'selected_headers',
                    label: intl.formatMessage(i18n.selectedHeaders),
                    name: intl.formatMessage(i18n.selectedHeaders),
                    type: FILTER_TYPES.LIST,
                    values: getFilterCollectionFromParentCollection(
                        data.relatedCollectionsFilters.availableHeaders.locationParams,
                        Object.values(data.mainCollection),
                        data.relatedCollectionsFilters.availableHeaders.filterCollection,
                    ),
                },
                {
                    dynamicFilter: true,
                    id: 'selected_tables',
                    label: intl.formatMessage(i18n.selectedTables),
                    name: intl.formatMessage(i18n.selectedTables),
                    type: FILTER_TYPES.LIST,
                    values: getFilterCollectionFromParentCollection(
                        data.relatedCollectionsFilters.availableTableViews.locationParams,
                        Object.values(data.mainCollection),
                        data.relatedCollectionsFilters.availableTableViews.filterCollection,
                    ),
                },
                ...systemFilters,
            ];
            break;
        case DATATABLE_TYPE.VALIDATION_RULES:
            defaultFilterOptions = [name, description, category, ...systemFilters];
            break;
        default:
            break;
    }

    filterParams.defaultFilterOptions = defaultFilterOptions.filter(
        (x) => !filtersToRemoveFromDefaultFilters.includes(x.name),
    );
    filterParams.userFilters = convertUserFilterInfo(
        type,
        data.userId,
        subscriptionId,
        defaultFilterOptions,
        {
            systemFilters: data.systemFilters,
            filterOperators: data.filterOperators,
        },
    );
    return filterParams;
};
